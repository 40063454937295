// src/components/PhotoCard.tsx
import React, { useState } from 'react';
import styles from '../styles/PhotoCard.module.css';

interface PhotoCardProps {
  src: string;
  caption: string;
  thumb?: string;
}

const PhotoCard: React.FC<PhotoCardProps> = ({ src, caption, thumb }) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <div>
      <div className={styles.photoCard}>
        <img src={thumb} alt={caption} className={styles.photo}
          style={{
            display: loaded ? `none` : `block`,
            filter: `blur(5px)`
          }}
        />
        <img src={src} alt={caption} className={styles.photo}
          style={{ display: loaded ? `block` : `none` }}
          onLoad={() => setLoaded(true)}
        />
      </div>
    </div>
  );
};

export default PhotoCard;
