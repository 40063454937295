import PhotoGrid from './components/PhotoGrid';
import styles from './styles/App.module.css';

const App: React.FC = () => {
  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <h1>My Photo Gallery</h1>
      </header>
      <PhotoGrid />
    </div>
  );
};

export default App;

